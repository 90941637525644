





























































































































































































































































import { Component } from 'vue-property-decorator';
import DialogComponent from '@/components/dialog.component.vue';
import { mixins } from 'vue-class-component';
import {
  APPIDS, COURSE_TYPE, RESERVE_EXAM_ROUTE_NAME, Rights, EXAM_CENTER_REQUEST_STATUS,
} from '../../enums';
import reserveExamProvider from '../../providers/reserveExam.provider';
import Toaster from '../../mixins/toaster.mixin';
import { Lang } from '../../types/general.type';
import { ExamRequest, ReservasionDataItem } from '../../types/exam.type';

@Component({
  components: {
    DialogComponent,
  },
})
export default class ViewReservationComponent extends mixins(Toaster) {
  loading = false;

  examRequest: ReservasionDataItem = {
    academicYear: '',
    examType: '',
    status: '',
    centers: [],
    semester: '',
    requestDate: '',
    turn: '',
    studentsCount: '',
    importStudentsType: '',
    signature: '',
    examDate: '',
    courseType: '',
    owner: { ar: '', en: '' },
    reviewer: { ar: '', en: '' },
    center: [],
    notes: '',
  };

  appId: string = APPIDS.RESERVE_EXAM;

  courseType = COURSE_TYPE;

  EXAM_CENTER_REQUEST_STATUS = EXAM_CENTER_REQUEST_STATUS;

  courseId: string = this.$route?.params.courseId;

  err = '';

  reservationId: string = this.$route?.params.reserveExamId;

  showDeleteConfirm = false;

  userRights: string[] = [];

  mounted() {
    this.getReservationData();
  }

  get canDelete() {
    return this.userRights.includes(Rights.delete);
  }

  async getReservationData() {
    try {
      this.loading = true;
      const { examRequest, rights }: ExamRequest = await reserveExamProvider.getReservationDetails(
        this.appId,
        this.courseId,
        this.reservationId,
      );
      this.userRights = rights;
      this.examRequest = examRequest;

      this.loading = false;
    } catch (err) {
      this.err = err;

      this.loading = false;
    }
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  get reservationData(): ReservasionDataItem {
    return {
      ...this.examRequest,
      examToDate: new Date(
        this.examRequest.examToDate as string,
      ).toLocaleTimeString(this.lang, {
        hour12: true,
        timeZone: 'Egypt',
      }) as string,
      examDate: new Date(
        this.examRequest.examDate as string,
      ).toLocaleTimeString(this.lang, {
        hour12: true,
        timeZone: 'Egypt',
      }) as string,
      requestDate: new Date(
        this.examRequest.examDate as string,
      ).toLocaleDateString(this.lang, { timeZone: 'Egypt' }),
    };
  }

  async deleteReservation() {
    try {
      await reserveExamProvider.deleteReservedExamData(
        this.appId,
        this.courseId,
        this.reservationId,
      );
      this.loading = false;
      this.goBack();
    } catch (err) {
      this.loading = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  goBack() {
    const appFilters = this.$store.state.filters.appFilters[this.appId];
    this.$router.push({
      name: RESERVE_EXAM_ROUTE_NAME.RESERVE_EXAMS_LIST,
      query: appFilters,
      params: {
        courseId: this.courseId,
      },
    });
  }
}
